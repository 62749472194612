header {
  box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
  border-bottom: 2px solid #fcc014;
  background-color: #021e4c;
  min-height: 80px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  color: #3b454e;
}
@media(min-width: 768px){
  body{      
    -webkit-font-smoothing: antialiased;
  }
}
header img{
  min-height: 20px;
}

#main{
  min-height: calc(100vh - 100px);
}
.breadcrumb li{
  line-height: 16px;
}
.breadcrumb li::after{
  display: inline-block;
  position: absolute;
  content: '';
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMkwxMSA4TDYuNDI4NTcgMTQiIHN0cm9rZT0iI0IzQkJDNiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
  height: 18px;
  width: 18px;
  background-repeat: no-repeat;
  background-position: right;
}
.breadcrumb li:last-child::after{
  height: 0px;
  width: 2px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table{
  font-size: 0.9rem;
}
table thead > td{
  font-weight: bold;
}
table thead > tr > td:last-child{
  text-align: right;
}

table tbody > tr:nth-child(odd){
  background-color: #f5f5f5;
}

table tbody > tr:nth-child(even){
  background-color: #f5f5f5;
}


table tbody > tr > td:last-child{
  text-align: right;
}

table tbody > tr:nth-child(odd){
  background-color: #f5f5f5;
}